import { Bindings } from "booking_app/types";
import { CheckoutButtonController } from "./checkout-button.controller";

export class CheckoutButtonComponent {
  bindings: any = {
    checkoutTranslationKey: Bindings.ONE_WAY,
    paymentIsLoading: Bindings.ONE_WAY,
    isDisabled: Bindings.ONE_WAY,
  };
  controller: any = CheckoutButtonController;
  template: string = `
    <main-button ng-show="!$ctrl.paymentIsLoading" type="submit" ng-disabled="$ctrl.isDisabled" has-relative-position="false">
      <center-content>
        <span ng-bind="$ctrl.checkoutButtonText()"></span>
        <i class="fa fa-chevron-right"></i>
      </center-content>
    </main-button>
    <main-button ng-show="$ctrl.paymentIsLoading">
    </main-button>
  `;
}

angular.module("BookingApp").component("checkoutButton", new CheckoutButtonComponent());
