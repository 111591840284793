import { MainButtonController } from "./main-button.controller";
import { Bindings } from "booking_app/types";

export class MainButtonComponent {
  bindings: any = {
    hasRelativePosition: Bindings.ONE_WAY,
    isDisabled: Bindings.ONE_WAY,
  };
  controller: any = MainButtonController;
  transclude: any = {
    leftContent: "?leftContent",
    centerContent: "?centerContent",
    rightContent: "?rightContent",
  };
  template: string = `
    <button ng-class="$ctrl.buttonClass()" ng-disabled="$ctrl.isDisabled">
      <div class="button-content">
        <div class="button-content-block" ng-if="$ctrl.hasLeftContent" ng-transclude="leftContent"></div>
        <div class="button-content-block" ng-if="$ctrl.hasCenterContent" ng-transclude="centerContent"></div>
        <div class="button-content-block" ng-if="$ctrl.hasRightContent" ng-transclude="rightContent"></div>
      </div>
    </button>
  `;
}

angular.module("BookingApp").component("mainButton", new MainButtonComponent());
