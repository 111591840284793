export class MainButtonController {
  static $inject = ["$transclude"];

  hasLeftContent: boolean;
  hasCenterContent: boolean;
  hasRightContent: boolean;

  // bindings
  hasRelativePosition: boolean;
  isDisabled: boolean;

  constructor(private $transclude: any) {

  }

  $onInit() {
    this.hasLeftContent = this.$transclude.isSlotFilled("leftContent");
    this.hasCenterContent = this.$transclude.isSlotFilled("centerContent");
    this.hasRightContent = this.$transclude.isSlotFilled("rightContent");
  }

  buttonClass(): object {
    return {
      "relative": this.hasRelativePosition,
      "fixed": !this.hasRelativePosition,
      "disabled": this.isDisabled,
    };
  }
}
