export class CheckoutButtonController {
  static $inject = ["$translate"];

  // Bindings
  checkoutTranslationKey: string;
  paymentIsLoading: boolean;
  isDisabled: boolean;

  constructor(private $translate: any) {
    this.$translate = $translate;
  }

  checkoutButtonText(): string {
    return this.$translate.instant(this.checkoutTranslationKey);
  }
}
